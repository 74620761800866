import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global varaible. In ES6, all imports are hoisted
// to the top of the file so if we used`import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
// import './custom/custom.js';


$(document).foundation().ready( function() {


    // Opens nav dropdown on desktop size
    function menuDropdownStatus() {
        if ($(window).width() >= 1300) {
            $('#menuDropdown').addClass('show');
            $('.header__menu-toggle-button').attr('aria-expanded', 'true');
        }
        else {
            $('#menuDropdown').removeClass('show');
            $('.header__menu-toggle-button').attr('aria-expanded', 'false');
        }
    };


    // ResizeEnd function
    var windowWidth = $(window).width();
    $(window).resize(function () {
        var newWindowWidth = $(window).width();

        if (windowWidth !== newWindowWidth) {
            if (this.resizeTO) {
                clearTimeout(this.resizeTO);
            }
            this.resizeTO = setTimeout(function () {
                $(this).trigger('resizeEnd');
            }, 150);
        }
        windowWidth = newWindowWidth;
    });


    // Runs on resizeEnd
    $(window).bind('resizeEnd', function () {

        menuDropdownStatus();

    });


    // Runs on document ready
    $(document).ready(function(){

        menuDropdownStatus();

    });


});